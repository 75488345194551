<template>
  <el-dialog
    v-el-drag-dialog
    :title="`选择${curItem.title}`"
    :visible.sync="dialogVisible"
    top="5vh"
    width="888px"
    custom-class="distribution-dialog dialog-header-border"
    append-to-body
    @opened="getElTableInstance"
    @closed="initCheckAllData"
  >
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="tableOption"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <template #menuLeft>
        <el-checkbox
          v-model="checkedAll"
          :indeterminate="indeterminate"
          :disabled="checkedAllDisabled"
          class="checkbox-large"
          @change="checkedAllChange"
          >全选</el-checkbox
        >
      </template>

      <template v-for="(item, name) in $scopedSlots" #[name]="scoped">
        <slot :name="name" v-bind="scoped"></slot>
      </template>

      <template #nameSearchForm>
        {{ info.name }}
      </template>

      <template #countSearchForm>
        {{ info.count }}
      </template>

      <template #memberLevelId="{ row }">
        {{ levelName(row) }}
      </template>

      <template #imgText="{ row }">
        <div class="flex-middle">
          <baseImage :src="getDisplayImagePath(row)" size="50" imgSize="50"></baseImage>
          <div class="ml10">{{ row.chineseName }}</div>
        </div>
      </template>

      <template #productCategoryId="{ row }">
        <div>{{ productCategory(row.productCategory) }}</div>
      </template>

      <template #thumbnailPath="{ row }">
        <div class="flex-center-middle">
          <defaultImg :src="aLiCompressPicByUrl(row.thumbnailPath, 50)"></defaultImg>
        </div>
      </template>
    </avue-crud>

    <el-divider></el-divider>

    <template v-if="isShowProtoForm">
      <ProtoForm ref="form" :form="form" :type="type" />
      <el-divider></el-divider>
    </template>

    <!-- 选择的原型列表 -->

    <div class="mt10">
      已选择{{ curItem.title }}
      <color-text-btn @click="clearSelection1" v-if="cancleTag.length > 0"
        >{{ cancleTag.title }} {{ cancleTag.length }}</color-text-btn
      >
    </div>

    <InfiniteScroll
      v-show="hasSelectionDataAll"
      class="checkbox-img"
      :span="4"
      :isShowLoading="false"
      v-loading="selectionDataAllLoading"
      :data="infiniteScrollData"
      :total="selectionDataAll.length"
      @load="load"
    >
      <template v-slot="{ data }">
        <component
          :disabledValue="disabledValue"
          :is="componentName"
          :prop="componentProp"
          :data="data"
          @toggleRowSelection="toggleRowSelection"
          v-bind="componentProps"
        />
      </template>
    </InfiniteScroll>

    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" v-if="showSubmit" :loading="selectionDataAllLoading || submitLoading" @click="onsubmit"
        >确 定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import InfiniteScroll from '@/components/infiniteScroll'
import ProtoForm from './module/protoForm'
import ProtoItem from './module/protoItem'
import PictureItem from './module/pictureItem'
import DistributorItem from './module/distributorItem'

import { avueCrud, dialogComponentsMixin, checkAllDataMixin } from '@/mixins'
import { getOption } from './const'
import { setDefault } from '@/components/avue/utils/util'
import { get, isArray, isFunction } from 'lodash'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  components: {
    InfiniteScroll,
    ProtoForm,
    ProtoItem,
    PictureItem,
    DistributorItem
  },

  mixins: [
    dialogComponentsMixin,
    avueCrud(),
    // 放在avueCrud之后，否则checkAllDataMixin的afterInit会被覆盖
    checkAllDataMixin()
  ],

  props: {
    value: {
      type: Array,
      default: () => []
    },

    disabledValue: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: 'default'
    },

    info: {
      type: Object,
      default: () => ({})
    },

    isEdit: Boolean,
    isExtend: Boolean,
    form: Object,
    resetMergeData: Object,
    resetInfiniteScrollPage: {
      type: Object,
      default: () => ({
        pageIndex: 1,
        pageSize: 12
      })
    },
    freezeResetMergeData: Object, //请求都必须带的参数freezeResetMergeData，再不传这个参数报错情况下使用
    option: Object, //传递option配置项，如果不传会通过getOption从配置const文件中获取
    keepSelectionDataAfterSearch: Boolean, //搜索之后保留之前选择的数据
    validSelectionDataAll: {
      type: [Boolean, Function],
      default: false
    }
  },

  data() {
    return {
      curType: '',
      submitLoading: false,
      tablePage: {
        pageIndex: 1,
        pageSize: 5,
        total: 0
      },
      infiniteScrollPage: this.resetInfiniteScrollPage
    }
  },

  computed: {
    //请求都必须带的参数freezeResetMergeData，再不传这个参数报错情况下使用
    requestAllDataPostData({ freezeResetMergeData }) {
      return Object.assign({}, REQUEST_ALL_DATA, freezeResetMergeData)
    },
    // 请求参数  请求都必须带的参数freezeResetMergeData，再不传这个参数报错情况下使用
    requestPostData({ freezeResetMergeData }) {
      return Object.assign({}, this.postData, freezeResetMergeData)
    },
    productCategory() {
      return (data) => {
        return data?.name
      }
    },
    cancleTag() {
      let value = this.selectionDataAll.map((row) => row.id)
      let length = 0
      let title = '取消全部'
      if (this.isExtend) {
        // 找出新增的
        value = value.filter((item) => !this.value.includes(item))
        if (value && value.length) {
          length = value.length
        }
        title = '取消'
      } else {
        length = this.selectionDataAll.length
      }
      return {
        length,
        title
      }
    },
    curItem({ type, option }) {
      const curItem = option || getOption(type)
      const { tableOption } = curItem
      if(!validatenull(this.disabledValue)) {
        tableOption.selectable = (row) => {
          const { disabledValue } = this
          if (!disabledValue.length) return true
          return !disabledValue.find((id) => row.id == id)
        }
      }
      return curItem
    },

    permisionList({ curItem }) {
      return curItem.permisionList || {}
    },

    showSubmit({ isExtend, isEdit, permisionList }) {
      return isExtend ? permisionList.hasExtend : isEdit ? permisionList.hasEdit : permisionList.hasAdd
    },

    getListFn({ curItem }) {
      return curItem.getListFn
    },

    submitFuncApi({ isExtend, isEdit, curItem }) {
      const { addSubmitFuncApi, editSubmitFuncApi, extendAddSubmitFuncApi } = curItem
      return isExtend ? extendAddSubmitFuncApi : isEdit ? editSubmitFuncApi : addSubmitFuncApi
    },

    componentName({ curItem }) {
      return curItem.componentName
    },

    componentProp({ curItem }) {
      return curItem.componentProp
    },

    componentProps({ curItem }) {
      return curItem.componentProps || {}
    },

    getDisplayImagePath() {
      return (row) => {
        return get(row, 'styleList.[0].styleDisplayImageList.[0].displayImagePath') || get(row, 'thumbnailPath')
      }
    },

    isShowProtoForm({ form }) {
      return !!form
    },

    infiniteScrollData({ selectionDataAll, infiniteScrollPage: { pageIndex, pageSize } }) {
      return selectionDataAll.slice(0, pageIndex * pageSize)
    },

    levelName() {
      return (row) => {
        return get(row, 'paymentAccountSimple.memberLevel.levelName') || '暂无'
      }
    }
  },

  watch: {
    curItem: {
      handler(n) {
        let { tableOption } = n
        tableOption && this.$store.dispatch('HandleOption', tableOption)
        this.tableOption = tableOption
      },
      deep: true,
      immediate: true
    },

    selectionDataAll: {
      handler(n) {
        let {
          infiniteScrollPage: { pageIndex, pageSize },
          infiniteScrollPage
        } = this
        let maxPage = Math.ceil(n.length / pageSize)
        // pageIndex最小为1，否则load不生效
        if (pageIndex > maxPage) infiniteScrollPage.pageIndex = Math.max(maxPage, 1)
      },
      deep: true
    },

    // 处理在全选并点击确定之后，全部数据未返回却打开弹窗时，选择数据为空的问题
    value: {
      handler(n) {
        if (this.dialogVisible) {
          this.setSelection(n)
        }
      },
      deep: true
    },

    checkedAll(n) {
      if (n || this.keepSelectionDataAfterSearch) return
      setTimeout(() => {
        this.setSelection(this.disabledValue)
      }, 0)
    },

    dialogVisible: {
      handler(n) {
        if (!n) {
          this.dialogClose('close')
        }
      },
      immediate: true
    }
  },

  methods: {
    clearSelection1() {
      this.clearSelection()
      setTimeout(() => {
        this.setSelection(this.disabledValue)
      }, 0)
    },

    async checkForm() {
      const {
        isShowProtoForm,
        $refs: { form }
      } = this
      if (!isShowProtoForm || !form) return true
      return await form.validate()
    },

    async onsubmit() {
      //校验表单
      if (!(await this.checkForm())) return
      // 防止在全选之后，立即点击确定，导致this.selectionDataAll数据缺少
      let selectionDataAll = await this.getSelectionDataAllArr()
      if (isFunction(this.validSelectionDataAll)) {
        const isNext = await this.validSelectionDataAll()
        if (!isNext) return
      } else if (!selectionDataAll.length && this.validSelectionDataAll) {
        return this.$message.warning('请先选择数据')
      }
      let value = selectionDataAll.map((row) => row.id)
      if (this.isExtend) {
        // 找出新增的id
        value = value.filter((item) => !this.value.includes(item))
        console.log('新添加的原型', value)
        if (!value.length || !value) {
          return this.$message.warning('没有新添加的原型')
        }
      }
      this.submitLoading = true
      const [err] = await this.doFunc(value)
      this.submitLoading = false
      if (err) return
      this.$message.success('操作成功')
      this.$emit('successHandler', value)
      this.$emit('input', value)
      this.dialogVisible = false
    },

    async doFunc(value) {
      if (this.$listeners.submit) {
        const res = await new Promise(resolve => {
          this.$emit('submit', value, resolve, this)
        })
        if (!isArray(res)) return [!res, res]
        return res
      }
      try {
        const { code, detail } = await this.submitFuncApi.call(this, value)
        return [!$SUC({ code }), detail || []]
      } catch {
        return [true, []]
      }
    },

    handleTableData(data) {
      if (this.curItem?.handleTableData) return this.curItem?.handleTableData(data)

      setDefault(data, ['styleList', 'sizeList'])
      return data.map((item) => {
        item.$styleName = item.styleList.map((style) => style.name).join('、')
        item.$sizeName = item.sizeList.map((style) => style.sizeName).join('、')
        return item
      })
    },

    load() {
      this.infiniteScrollPage.pageIndex++
    },

    async dialogOpen(value = this.value, type = this.type) {
      this.dialogReturnType = ''
      this.curType = type
      this.dialogVisible = true
      this.setSelection(value)

      this.searchForm = {}
      this.$nextTick(function () {
        this.$refs.crud?.formInit()
      })

      //this.searchChange()
      return new Promise((resolve, reject) => {
        this.dialogResolveFn = resolve
        this.dialogRejectFn = reject
      })
    },

    dialogClose(value) {
      let fnType = value === 'close' ? 'dialogReject' : 'dialogResolve'
      if (this.dialogReturnType === 'success' && fnType === 'dialogReject') return
      return this[fnType](value)
    },

    dialogResolve(value) {
      return typeof this.dialogResolveFn === 'function' && this.dialogResolveFn(value)
    },

    dialogReject(value) {
      return typeof this.dialogRejectFn === 'function' && this.dialogRejectFn(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution-dialog {
  .formPart {
    margin: 0;
  }
  .checkbox-img {
    min-height: 100px;
    max-height: 300px;
  }
}
::v-deep {
  .checkbox-img {
    .checkbox-img-col {
      margin-bottom: 15px;
    }
  }
}
</style>
